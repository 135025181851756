<template>
    <span>
        <v-navigation-drawer
            app
            v-model="drawer"
            class="pink lighten-2"
            dark
            disable-resize-watcher
        >
            <v-list>
                <template v-for="(item, index) in items">
                    <v-list-tile :key="index">
                        <v-list-tile-content>
                            {{ item.title }}
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-divider :key="`divider-${index}`"></v-divider>
                </template>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar app color="orange darken-4" dark>
            <v-app-bar-side-icon
                class="hidden-md-and-up"
                @click="drawer = !drawer"
            ></v-app-bar-side-icon>
            <v-spacer class="hidden-md-and-up"></v-spacer>
            <router-link to="/">
                <v-app-bar-title to="/">{{ appTitle }}</v-app-bar-title>
            </router-link>
            <v-btn text class="hidden-sm-and-down" to="/menu">PRODUCTS</v-btn>
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-btn color="blue lighten-3" class="hidden-sm-and-down" to="/About"
                >ADMIN</v-btn
            >
        </v-app-bar>
    </span>
</template>
<script>
export default {
    name: 'AppNavigation',
    data() {
        return {
            appTitle: 'CJs e-store',
            drawer: false,
            items: [{ title: 'Menu' }, { title: 'Cart' }],
        };
    },
};
</script>
<style scoped>
a {
    font: status-bar;
    padding-right: 10px;
    text-decoration: white;
    text-decoration-color: white;
}
</style>
