<template>
    <div class="card my-5">
        <h1> Available Products</h1>
        <div class="card-body">
            <h5 class="text-center card-title">{{ product.title }}</h5>
            <h5 class="text-center card-title">{{ product.category }}</h5>
            <h5 class="text-center card-title">{{ product.description }}</h5>
            <v-responsive>
                <v-img
                    :src="product.image"
                    height="250px"
                    width="250px"
                ></v-img>
            </v-responsive>

            <p class="text-center text-muted card-text display-4">
                ${{ Number(product.price).toFixed() }}
            </p>
            <button
                :disabled="isInCart"
                @click="$emit('add-to-cart', product)"
                class="btn btn-info form-control"
            >
                {{ isInCart ? 'Added to cart' : 'Add to Cart' }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'product',
    props: ['product', 'isInCart'],
};
</script>
