<template>
    <v-container>
        <v-layout column>
            <v-flex class="display-2 text-xs-center my-5"
                >CJs Phones and Whatnot</v-flex
            >
            <v-flex>
                <grid>
                    <h1 class="headline mt-3">Who are we?</h1>
                    <p class="subheading mt-3">
                        Purchasers of products most people consume. From phones
                        to electronics we provide you with what's necessary.
                    </p>
                    <h1 class="headline mt-3">How it works</h1>
                    <p class="subheading mt-3">
                        We scout the market for products we deem worthy of their
                        price and purchase them for a lower fee so we can sell
                        them to our customers for a lower fee. Win win!
                    </p>
                    <h1 class="headline mt-3">Anyways...</h1>
                    <p class="subheading mt-3">
                        Should you be interested in buying from us we regret to
                        inform you that we only take BitCoin, DogeCoin or cash
                        for payments at this point in time.
                    </p>
                </grid>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'HomePage',
};
</script>

<style scoped></style>
