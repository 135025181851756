<template>
    <div>
        <shopping-cart></shopping-cart>
        <product></product>
        <ul class="list-group">
            <li class="list-group-item" v-for="item in items" :key="item.id">
                {{ item.title }} - $ {{ item.price }} - Category:
                {{ item.category }}
                <button
                    @click="$emit('remove-from-cart', item)"
                    class="btn badge badge-warning float-right"
                >
                    Remove
                </button>
            </li>
        </ul>
        <div class="card p-3 my-5">
            <h4 class="text-center">${{ total }}</h4>
        </div>
        <p>
            <strong> Info: </strong><br />
            <input v-model="name" placeholder="Name" />
        </p>
        <p>
            <input v-model="surName" placeholder="Surname" />
        </p>
        <p>
            <input v-model="adress" placeholder="Adress" />
        </p>
        <p>
            <input v-model="city" placeholder="City" />
        </p>
        <p>
            <input v-model="phoneNr" placeholder="PhoneNR" />
        </p>
        <p>Select Delivery Option:</p>

        <div>
            <input
                type="radio"
                id="home"
                name="delivery"
                value="Home Delivery"
                checked
            />
            <label for="home">Home Delivery</label>
        </div>

        <div>
            <input
                type="radio"
                id="postal"
                name="delivery"
                value="Postal Service"
            />
            <label for="postal">Postal Service</label>
        </div>
        <button
            :disabled="items.length === 0"
            @click="$emit('pay')"
            class="btn btn-info form-control"
        >
            Pay Now
        </button>
    </div>
</template>
<script>
import Product from '../components/Product.vue';
import ShoppingCart from '../components/ShoppingCart.vue';

export default {
    name: 'Cart',
    components: {
        ShoppingCart,
        Product,
    },
    props: ['items'],
    computed: {
        total() {
            return this.items.reduce(
                (acc, item) => acc + Number(item.price),
                0
            );
        },
    },
};
</script>
