<template>
    <span>
        <home-helper></home-helper>
        <home-page></home-page>
        <shopping-cart></shopping-cart>
    </span>
</template>

<script>
import HomeHelper from '@/components/HomeHelper.vue';
import HomePage from '../components/HomePage.vue';
import ShoppingCart from '../components/ShoppingCart.vue';

export default {
    name: 'Home',
    components: {
        HomeHelper,
        HomePage,
        ShoppingCart,
    },
};
</script>
