<template>
    <v-container
        fluid
        fill-height
        class="home-helper"
        style="max-height: 100vh"
    >
        <v-layout justify-center align-center column pa-5>
            <div class="display-4 font-weight-black white--text text-xs-center">
                CJs SHOP
            </div>
            <div
                class="
                    display-4
                    font-weight-black
                    white--text
                    text-xs-center
                    mb-3
                "
            >
                TEACH DEALERSHIP 
            </div>
            <div class="display-1 font-weight-bold white--text text-xs-center">
                Get new products for a fair price.
            </div>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'HomeHelper',
};
</script>

<style scoped>
.home-helper {
    background: url('https://images.pexels.com/photos/169789/pexels-photo-169789.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
    background-size: cover;
    width: 100%;
    height: 100%;
}
</style>
